$primary: #9ac322;/* MAIN COLOR */
$secondary: #308805; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

@import url('https://fonts.googleapis.com/css?family=Cinzel+Decorative|Kaushan+Script');

// font-family: 'Kaushan Script', cursive;
// font-family: 'Cinzel Decorative', cursive;


html, body {
    height: 100%;
}

body {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.flash {
    display: none;
}

q,
blockquote {
    quotes: "“" "”";
}

.navbarFixed {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
}


/* Change autocomplete styles in WebKit */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #0065dd;
    -webkit-text-fill-color: #0065dd;
    -webkit-box-shadow: 0 0 0px 1000px #000 inset;
    transition: background-color 5000s ease-in-out 0s;
}

nav.navbar {
    z-index: 1000;
    border: none;
    border-radius: $border-radius;
    background: #fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.20);
    .navbar-nav {
        >li>a {
            text-align: center;
            margin-top: 22px;
            display: flex;
            align-items: center;
            color: $blk;
            transition-duration: 0.5s;

            @media (max-width: 767px) {
                margin-top: 0;
                padding: 6px 0px;
                display: inline-block;
            }
            &:focus,
            &:active {
                background: transparent;
                color: $blk;
                outline: 0;
            }
            &:hover {
            	border-radius: $border-radius;
                background: $primary;
                color: $wht;
                @media (max-width: 1024px) {
                    background: transparent;
                    color: $blk;
                    outline: 0;
                }
            }
        }
    }
}

.navbar-right {
    margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
}

/** LOGIN FORM **/

@mixin btn {
    border: none;
    border-radius: $border-radius;
    background: $primary;
    color: $wht;
    padding: 0.5em 2em;
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    display: inline-block;
    margin: 2em 0em;
    transition: 0.7s ease all;
    &:hover {
        background: lighten($primary, 10%);
        color: lighten($wht, 10%);
        text-decoration: none;

        @media (max-width: 1024px) {
            background: $secondary;
            color: $wht;
            box-shadow: 0px 0px 5px $blk;
        }
    }
}

.btn-default {
    @include btn;
}

.modal-dialog {
    max-width: 300px;
    text-align: center;
    margin: 6em auto;
    .close {
        display: none;
    }
    .modal-content {
        color: $primary;
        h2 {
            text-align: center;
        }
    }
    input {
    	color:#000;
        border: 1px solid #777;
        text-align: center;
    }
    input#username,
    input#password {
        @media (max-width: 1024px) {
            font-size: 16px;
        }
    }
    button {
        background: transparent;
        color: $wht;
        display: block;
        border: none;
        padding: 1em 2em;
        margin: 0 auto;
        font-size: 18px;
        &:hover {
            background: transparent;
            color: #fff;
            box-shadow: none;
            text-shadow: 0px 0px 3px $blk;
            border: none;
        }
    }
    input[type="submit"] {
        @include btn;
        display: block;
        width: 50%;
        font-size: 18px;
        margin: 0 auto;
    }
}

.modal-header,
.modal-footer {
    background: $primary;
    color: $wht;
}

.modal-open {
    overflow: auto;
    padding-right: 0px !important;
}

input#username {
    margin-bottom: 20px;
}


/** END LOGIN FORM **/

footer {
    padding: 40px 0px 20px;
    background: $secondary;
    color: $footerLinks;
    font-size: 15px;
    a {
        color: $footerLinks;
        white-space: nowrap;
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
        &:hover {
            color: lighten($footerLinks, 10%);
            outline: 0 !important;
            text-decoration: none;
        }
        &:focus {
            color: $wht;
            outline: 0 !important;
            text-decoration: none;
        }
    }
    p {
        font-size: 15px;
        @media (max-width: 990px) {
            font-size: 13px;
        }
    }
}

input#username,
input#password {
    width: 100%;
    @media (max-width: 1024px) {
        font-size: 16px;
    }
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}

a,
a:hover,
a:focus,
a:active {
    outline: 0 !important;
}

.logo {
    max-width: 250px;
    padding: 0.5em;
}

@media(max-width: 767px) {
    .logo {
        max-width: 175px;
    }
    .navbar-header a {
        float: left;
    }
}

@media (max-width: 600px) {
    .logo {
        max-width: 125px;
    }
    .navbar-toggle {
        margin-top: 10px;
    }
}

.top-pad {
    padding: 3em;
    background: white;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
        display: block;
        /* may need to be inline-block */
    }
}

.terms,
.privacy {
    p {
        font-size: 15px;
    }
    
    & ol {
        li {
            font-size: 1em;
        }
        & ol li {
            font-size: 0.75em;
        }
    }
}


.form {
	//background: url(../img/form-bg.jpg);
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/form-bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 75%;
	padding: 150px 0px;

	@media (max-width: 991px) {
		background-position: 80% 75%;
	}
	
	@media (max-width: 767px) {
		background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)), url(../img/form-bg.jpg);
		padding: 25px 0px;
		background-position: 80% 75%;	
	}

	@media (max-width: 568px) {
		background-position: 85% 75%;
	}

	h1{
		font-family: 'Cinzel Decorative', cursive;
		color: #fff;
	}

	p{
		font-family: 'Kaushan Script', cursive;
		font-size: 18px;
		line-height: 30px;
		color:#fff;
		

		@media (max-width: 767px) {
			font-size: 15px;
			line-height: 22px;
		}

	}
}

.shadowthis {
text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.75);
}

.padTop{
	padding-top: 25px;
}

.btn {
  font-family: 'Quicksand', sans-serif;
  background-color: $primary;
  // border-color: rgba(255,255,255,1); 
  font-size: 15px;
  padding: 1em 2em;
  color: $wht;

    &:hover {
        background-color: $secondary; 
        //border-color: rgba(255,255,255,1); 
        color: $wht;

        @media (max-width: 1366px) {
            background-color: $primary;
            color: $wht;
        }

    &:active, &:focus {
        background-color: $primary;
        color: $wht !important;
        }
    }
}

.divider{
 padding: 0px 0px;

 	h1 {
		font-family: 'Cinzel Decorative', cursive;
		color: #fff;

		font-size: 26px;

		@media (max-width: 767px) {
			font-size: 22px;
		}

		@media (max-width: 568px) {
			font-size: 20px;
		}
	}

}


.img1 {
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/img1.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% -15%;
	padding: 240px 0px;

	@media (max-width: 991px) {
		padding: 230px 0px;
		background-position: 20% 0%;
	}

	@media (max-width: 768px) {
		background-position: 20% 0%;
		padding: 240px 0px;
	}
	@media (max-width: 767px) {
		background-position: 5% 0%;
		padding: 240px 0px;
	}

	@media (max-width: 600px) {
		background-position: 21% 0%;
		padding: 175px 0px;
	}
}

.img2 {
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/img2.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 100% 5%;
	padding: 240px 0px;

	@media (max-width: 991px) {
		background-position: 77% 5%;
		padding: 240px 0px;
	}

	@media (max-width: 767px) {
		background-position: 100% 5%;
		padding: 225px 0px;
	}

	@media (max-width: 600px) {
		background-position: 85% 5%;
		padding: 175px 0px;
	}
}

.logo2 {
	padding: 25px;
	margin: 0px auto;
}


.outro {
	background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.5)), url(../img/banner.jpg);
	background-repeat: no-repeat;
	background-size: cover;
    background-position: 50% 50%;
    padding: 300px 0px 80px 0px;

	@media (max-width: 767px) {
		background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.75)), url(../img/banner.jpg);
		padding: 150px 0px 50px 0px;
		background-position: 50% 50%;	
	}

	@media (max-width: 568px) {
		padding: 50px 0px 25px 0px;
		background-position: 50% 50%;	
	}

	h1 {
		font-family: 'Kaushan Script', cursive;
		color: #fff;

		@media (max-width: 767px) {
			font-size: 25px;
		}

		@media (max-width: 568px) {
			font-size: 18px;
		}
	}
}